import React, {useState, useEffect} from "react";
import orbita from '../assets/images/orbita.svg'

export default () => {

  const texts = [
    "Catálogo de API's",
    "Datos de venta al día",
    "KPI Performance Ecommerce",
    "Perfiles de clientes",
    "100% Cloudbased",
    "Alertas"
  ];

  const desc = [
    "Te entregamos un completo catálogo de API's para facilitar la integración de datos en grandes volúmenes",
    "Accede al modulo NRT y tendrás las métricas de venta a tiempo real",
    "Obtiene de forma precisa los principales indicadores para medir el desempeño de la venta Ecommerce Found Rate, On time, OLA, entre otros",
    "Descubre que tipos de clientes consumen tus productos identifica oportunidades para mejorar la experiencia de tus clientes",
    "Todo desarrollado en un ambiente Cloud con enfoque Big Data para que puedan manejar datos a gran escala con un acceso seguro y eficiente",
    "Contamos con un modulo de suscripciones que nos permite automatizar correos de alertas a cada uno de ustedes, para tomar acciones de forma rápida y efectiva"
  ]

  return (
    <div className="component soluciones-component" id="Soluciones">
      
        <div className="orbita" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="400">
          <img src={orbita} width="100%" />
          <div className="sat"><span></span></div>
        </div>
        
          <div className="mid">&nbsp;</div>

          <div className="mid">
            <h2>Tenemos múltiples soluciones para ti</h2>
            <p>Estudia los distintos indicadores que te entregamos para hacer crecer tu negocio</p>
          </div>
        

        <div className="float-txt ft1" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="1600">{ texts[0] }<span>{ desc[0] }</span></div>
        <div className="float-txt ft2" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2400">{ texts[1] }<span>{ desc[1] }</span></div>
        <div className="float-txt ft3" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2000">{ texts[2] }<span>{ desc[2] }</span></div>
        <div className="float-txt ft4" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="600">{ texts[3] }<span>{ desc[3] }</span></div>
        <div className="float-txt ft5" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2800">{ texts[4] }<span>{ desc[4] }</span></div>
        <div className="float-txt ft6" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="3200">{ texts[5] }<span>{ desc[5] }</span></div>
      
    </div>
  );
};
