import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import orbita from '../assets/images/orbita.svg'

const Desarrollo = () => {

  const texts = [
    "Desarrollamos un modelo que permite simular la disponibilidad real en góndola, todo en base a lógicas de negocio y comportamiento de rotación y abastecimiento de cada combinación.",
    "Identifica la causa de los quiebres en góndola y derívalo a cada responsable para generar una acción concreta de cara a los clientes.",
    "La automatización del modelo permite un enfoque directo a la Gestión sobre la mejora en Disponibilidad, evitando que las distintas áreas incurran en análisis que tomen tiempos extensos en búsqueda del problema y solución.",
    "Detecta productos con Stock en Bodega o Stock Fantasma que están influyendo en los resultados de la venta eCommerce, genera acciones que influyan positivamente en la experiencia de compra de nuestros clientes",
    "Cencosud tiene las herramientas para generar este desarrollo, utilizamos mas de 100 indicadores para poder entregar una precisión de mas de un 95%"
  ];

  return (
    <div className="component desarrollo-component">
      
      <div className="orbita" data-aos="fade-down" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="400">
          <img src={orbita} width="100%" />
          <div className="sat"><span></span></div>
        </div>
        
        <div className="mid">
        <h2>¿Quieres mejorar tu Disponibilidad?</h2>
        <p>Conoce el Modulo OSA, indicador que no puede faltar en tu estrategia para mejorar la experiencia de compra de tus clientes</p>
        </div>
        <div className="mid">
        <Tabs>
            <TabList>
                <Tab>Disponibilidad</Tab>
                <Tab>Motivo Quiebre</Tab>
                <Tab>Eficiencia</Tab>
                <Tab>Stock Fantasma</Tab>
                <Tab>Precisión</Tab>
            </TabList>

            <TabPanel>
                <div className="float-txt ft1">{ texts[0] }</div>
            </TabPanel>
            <TabPanel>
                <div className="float-txt ft2">{ texts[1] }</div>
            </TabPanel>
            <TabPanel>
                <div className="float-txt ft3">{ texts[2] }</div>
            </TabPanel>
            <TabPanel>
                <div className="float-txt ft4" >{ texts[3] }</div>
            </TabPanel>
            <TabPanel>
                <div className="float-txt ft5">{ texts[4] }</div>
            </TabPanel>
        </Tabs>
            
            
            
            
            
        </div>
  
    </div>
  );
};

export default Desarrollo;