/* eslint-disable react/no-direct-mutation-state */

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Principal from './pages/Home';
import Cau from './pages/CAU';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact={true} element={<Principal/>}/>
        <Route path="/cau" exact={true} element={<Cau/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
