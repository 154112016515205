import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Cau from "../components/Cau";

import logo from '../assets/images/logo.svg';
import '../App.css';

import flag from '../assets/images/flag.png';
import m1 from '../assets/images/m1.png';
import m2 from '../assets/images/m2.png';
import m3 from '../assets/images/m3.png';


const CAU = () => {

    AOS.init();

    return (
        <>
            <div className="wrapper">
                <header>
                    <div className="contenedor">
                    <div className="logo"><img src={logo} width="150" /></div>
                    <nav>
                        <ul>
                        <li className="desktop">
                            <AnchorLink href="/#Mipartner">Conócenos</AnchorLink>
                        </li>
                        <li className="desktop">
                            <AnchorLink href="/#Beneficios">Beneficios</AnchorLink>
                        </li>
                        <li className="desktop">
                            <AnchorLink href="/#Soluciones">Features</AnchorLink>
                        </li>
                        {/* <li>
                            <a href="#">Video</a>
                        </li> */}
                        <li>
                            <a href="MicroStrategyLibraryDS/auth/ui/loginPage" className="btn">Ingresar</a>
                        </li>
                        <li>
                            <AnchorLink href="/#Contacto" className="btn2">Agenda un demo</AnchorLink>
                        </li>
                        </ul>
                    </nav>
                    </div>
                </header>
            
                <Cau />
            
                <footer>
                    <div className="contenedor">
                        <div className="flag desktop"><img src={flag} width="50" /></div>
                        <span>seamos partners <strong>mipartner@cencosud.cl</strong></span>
                        <div className="brands">
                            <img src={m1} width="50" />
                            <img src={m2} width="50" />
                            <img src={m3} width="50" />
                        </div>
                    </div>
                </footer>

            </div>
        </>
    );
  
}

export default CAU
