import React, { useEffect, useState } from "react";
import orbita from '../assets/images/orbita.svg'
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const Principal = () => {

  // AOS.init();
  const [texts, setTexts] = useState("");

  useEffect(() => {
    
    let nums = ["omnicanalidad", "gestión", "comunicación", "atención personalizada", "precisión", "big data", "métricas", "oportunidad"],//all numbers to be randomized
      ranNums = [],
      i = nums.length,
      j = 0;

    while (i--) {
      j = Math.floor(Math.random() * (i+1));
      ranNums.push(nums[j]);
      nums.splice(j,1);
    }

    setTexts(ranNums);

	}, []);

  return (
    <div className="component first-component">
      
      <div className="orbita" data-aos="fade-down" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="400">
        <div className="sat"></div>
      </div>
      <h2><strong>Conéctate a Datasharing Cencosud</strong> y hagamos crecer juntos tu negocio</h2>
      <div className="float-txt ft1" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="1600">{ texts[0] }</div>
      <div className="float-txt ft2" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2400">{ texts[1] }</div>
      <div className="float-txt ft3" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2000">{ texts[2] }</div>
      <div className="float-txt ft4" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="600">{ texts[3] }</div>
      <div className="float-txt ft5" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="2800">{ texts[4] }</div>
      <div className="float-txt ft6" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="1000">{ texts[5] }</div>
      <div className="float-txt ft7" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="1400">{ texts[6] }</div>
      <div className="float-txt ft8" data-aos="fade-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="0" data-aos-delay="1200">{ texts[7] }</div>
    </div>
  );
};

export default Principal;