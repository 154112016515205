import React from "react";


import mip from "../assets/images/d.png"
import orbita from '../assets/images/orbita.svg'

const Datasharing = () => {

  

  return (
    <div className="component second-component" id="Mipartner">
      <div className="contenedor">
      <div className="mid" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400" data-aos-offset="200">
        <div className="orbita"><div className="sat"></div></div>
        <img src={mip} alt="mip" className="logoMip"/>
        {/* <img src={mip} alt="mip" className="logoMip blur"/> */}
      </div>
      <div className="mid">
        <h2>Conoce nuestro catálogo de API'S</h2>
        <p>Tenemos muchas API's para ti y tu negocio.</p>
        <a href="https://datasharing.cencosud.com/DSAPI.html">Mira nuestro catálogo <span className="material-symbols-outlined">arrow_outward</span></a>
      </div>
      </div>
    </div>
  );
};

export default Datasharing;