import React, {useState, useEffect} from "react";
import orbita from '../assets/images/orbita.svg'

export default () => {
  const [texts, setTexts] = useState("");

  useEffect(() => {
    
    let nums = ["Catálogo de API's", "Datos de venta al día", "Found rate, Fillrate & OnTime Internet", "Perfiles de clientes", "100% Cloudbased", "KPI OSA"],
      ranNums = [],
      i = nums.length,
      j = 0;

    while (i--) {
      j = Math.floor(Math.random() * (i+1));
      ranNums.push(nums[j]);
      nums.splice(j,1);
    }

    setTexts(ranNums);

	}, []);

  return (
    <div className="component contacto-component" id="Contacto">
      
        <div className="orbita" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="400">
          <img src={orbita} width="100%" />
          <div className="sat"><span></span></div>
        </div>
        
          <div className="mid">
            <h2>Seamos Partners</h2>
            <p>¿Tienes preguntas o dudas? Escríbenos un mensaje y empieza ahora a crecer con nosotros</p>
          </div>

          <div className="mid">
              <form>
                    <div className="campo">
                        <div className="campo-m">
                            <input type="text" placeholder="Déjanos tú nombre" />
                        </div>
                        <div className="campo-m">
                            <input type="text" placeholder="Déjanos tú apellido" />
                        </div>
                    </div>
                    <div className="campo">
                        <input type="text" placeholder="Tú email" />
                    </div>
                    <div className="campo">
                        <textarea placeholder="Escríbenos..."></textarea>
                    </div>
                    <div className="campo">
                        <button className="btn">Enviar <span className="material-symbols-outlined">arrow_outward</span></button>
                    </div>
              </form>
          </div>
      
    </div>
  );
};
